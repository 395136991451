import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFile, faX } from "@fortawesome/free-solid-svg-icons";
import { allSalesReport, saleReport } from "../../Services/apis";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { formatDate } from "../../Utils/helpers";
import ExpandableTable from "../../Components/Table";
import AllSalesTable from "../../Components/AllSalesTable";
import VirtualTable from "../../Components/VirtualTable";

const Sales = () => {
  const [activeTab, setActive] = useState(1);
  const [sales, setSales] = useState([]);
  const [allSales, setAllsales] = useState([]);
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const { user } = useSelector((state) => state.auth);

  const [filterData, setFilterData] = useState({
    from: moment().toDate(),
    to: moment().toDate(),
    // page_size: 15,
    // period: "today",
    product_type: "",
  });

  const fetchResult = (id) => {
    const data = { ...filterData };
    data.from = formatDate(data.from, "YYYY-MM-DD");
    data.to = formatDate(data.to, "YYYY-MM-DD");
    data.product_type = activeTab;
    if (activeTab === 1) {
      allSalesReport(data)
        .then((res) => {
          if (res.success) {
            setAllsales(res.data);
            // setSalesSummary(res.data?.summary);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err?.response);
          toast.error("Unable to fetch results");
        });
    } else {
      saleReport(data)
        .then((res) => {
          if (res.success) {
            setSales(res);
            // setSalesSummary(res.data?.summary);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err?.response);
          toast.error("Unable to fetch results");
        });
    }
  };
  const handleChange = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
    if (name === "from" || name === "to") {
      // setFilterData({ ...filterData, period: "custom", [name]: value });
    } else {
      setFilterData({ ...filterData, [name]: value });
    }
  };

  const setDateRange = (e) => {
    const period = e.target.value;
    switch (period) {
      case "today":
        setFilterData({
          ...filterData,
          from: moment().toDate(),
          to: moment().toDate(),
          period,
        });
        break;
      case "yesterday":
        setFilterData({
          ...filterData,
          from: moment().subtract(1, "day").toDate(),
          to: moment().subtract(1, "day").toDate(),
          period,
        });
        break;
      case "custom":
        setFilterData({
          ...filterData,
          period,
        });
        break;
      default:
        break;
    }
    // fetchResult();
  };

  return (
    <div className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt" style={{ marginTop: "-1rem" }}>
                  <h4
                    className="p-4 commission-head"
                    style={{
                      background: "#f29120",
                      borderBottom: ".001px solid white",
                    }}
                  >
                    Sales Report
                  </h4>
                </div>
                <div className="cashbook">
                  {user?.role == "Shop" && (
                    <ul className="t-header ">
                      <li
                        style={{ marginLeft: "-2.5rem" }}
                        onClick={() => setActive(1)}
                        className={
                          activeTab === 1 ? `active tab-item ml` : "tab-item ml"
                        }
                      >
                        ALL PRODUCTS ANALYSIS
                      </li>
                      <li
                        onClick={() => setActive("sports")}
                        className={
                          activeTab === "sports"
                            ? `active tab-item`
                            : "tab-item"
                        }
                      >
                        SPORTS BY CHANNEL
                      </li>
                      <li
                        onClick={() => setActive("virtual")}
                        className={
                          activeTab === "virtual"
                            ? `active tab-item`
                            : "tab-item"
                        }
                      >
                        VIRTUAL BY CHANNEL
                      </li>
                    </ul>
                  )}
                  {user?.role == "Cashier" && (
                    <ul className="t-header ">
                      <li
                        style={{ marginLeft: "-2.5rem" }}
                        onClick={() => setActive(1)}
                        className={
                          activeTab === 1 ? `active tab-item ml` : "tab-item ml"
                        }
                      >
                        ALL PRODUCTS ANALYSIS
                      </li>
                    </ul>
                  )}
                  <p
                    className="p-4"
                    style={{
                      background: "white",
                      color: "",
                      marginTop: "-.1px",
                      marginBottom: ".05rem",
                      // borderBottom: ".5px solid red",
                    }}
                  >
                    Use the date flters below to generate the report.
                  </p>
                  <div className="radio">
                    <h3>Select your filter options</h3>
                    <div className="radio-group">
                      <div className="input-group">
                        <input
                          type="radio"
                          name="period"
                          value="today"
                          checked={filterData.period === "today"}
                          onChange={(e) => setDateRange(e)}
                          id="today"
                        />
                        <label htmlFor="today">Today</label>
                      </div>
                      <div className="input-group">
                        <input
                          type="radio"
                          name="period"
                          value="yesterday"
                          checked={filterData.period === "yesterday"}
                          onChange={(e) => setDateRange(e)}
                          id="yesterday"
                        />
                        <label htmlFor="Yesterday">Yesterday</label>
                      </div>
                      <div className="input-group">
                        <input
                          type="radio"
                          name="day"
                          value="custom"
                          checked={filterData.period === "custom"}
                          onChange={(e) => setDateRange(e)}
                          id="custom"
                        />
                        <label htmlFor="Manual">Manual</label>
                      </div>
                    </div>
                    <div className="radio-group">
                      <div className="input-group">
                        <input
                          type="radio"
                          name="product"
                          value="all"
                          id="All"
                          onChange={(e) => handleChange("product", "all")}
                          checked={filterData.product === "all"}
                        />
                        <label htmlFor="All">All</label>
                      </div>
                      <div className="input-group">
                        <input
                          type="radio"
                          name="product"
                          value="retail"
                          checked={filterData.product === "retail"}
                          onChange={(e) => handleChange("product", "retail")}
                          id="retail"
                        />
                        <label htmlFor="Retail">Retail</label>
                      </div>
                      <div className="input-group">
                        <input
                          type="radio"
                          name="product"
                          value="online"
                          onChange={(e) => handleChange("product", "online")}
                          checked={filterData.product === "online"}
                          id="online"
                        />
                        <label htmlFor="Online">Online</label>
                      </div>
                    </div>
                  </div>
                  <div className="date-group">
                    <div className="input-group date">
                      <label htmlFor="today">Date From:</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={filterData.from}
                        className="dropdownFiltoData"
                        maxDate={moment().toDate()}
                        onChange={(date) => handleChange("from", date)}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="today">Date To :</label>{" "}
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        maxDate={moment().toDate()}
                        selected={filterData.to}
                        className="dropdownFiltoData"
                        onChange={(date) => handleChange("to", date)}
                      />
                    </div>
                  </div>
                  <div className="proceed">
                    <div className="cancel-btn">
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: "red",
                        }}
                      />
                      <button>Cancel</button>
                    </div>
                    <div
                      className="proceed-btn"
                      onClick={() => fetchResult(user?.id)}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: "green",
                        }}
                      />
                      <button>Proceed</button>
                    </div>
                  </div>
                  {sales?.data?.length > 0 && activeTab === "sports" && (
                    <React.Fragment>
                      <ExpandableTable
                        data={sales}
                        currency={SportsbookGlobalVariable.Currency}
                      />
                    </React.Fragment>
                  )}
                  {sales?.data?.length > 0 && activeTab === "virtual" && (
                    <React.Fragment>
                      <VirtualTable
                        data={sales}
                        currency={SportsbookGlobalVariable.Currency}
                      />
                    </React.Fragment>
                  )}
                  {Object.keys(allSales).length > 0 && activeTab === 1 && (
                    <React.Fragment>
                      <AllSalesTable
                        data={allSales}
                        currency={SportsbookGlobalVariable.Currency}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
